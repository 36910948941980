(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("survey-core"));
	else if(typeof define === 'function' && define.amd)
		define("i18n/french", ["survey-core"], factory);
	else if(typeof exports === 'object')
		exports["i18n/french"] = factory(require("survey-core"));
	else
		root["SurveyLocales"] = factory(root["Survey"]);
})(this, (__WEBPACK_EXTERNAL_MODULE_survey_core__) => {
return 